.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 10vmin;
  font-family: 'Open Sans Condensed';
}

hr {
  margin: 25px 0;
  height: 1px;
  width: 90vmin;
  border: 0;
  border-top: 1px solid #aaa;
}

.App .header {
  font-weight: bold;
  font-size: 1.2em;
}

.App .small {
  font-size: 0.9em;
  font-weight: 300;
}

.App .money {
  font-size: 1.5em;
  letter-spacing: 0.5rem;
  margin-bottom: 0.7em;
}

.App button {
  color: #eee;
  font-size: 0.7em;
  background: none;
  text-align: center;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Open Sans Condensed';
  display: block;
}

.App .detailEntry {
  margin-bottom: 0.7em;
}

.App .counter {
  font-size: 1.7em;
  font-weight: 700;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
